.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 300ms ease-in-out;
  z-index: 3;
  /* border: 1px solid #000000; */
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
  outline: none;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}

.overlayMyPage-base {
  outline: none;
  opacity: 0;
  transform: translateX(200px);
  transition: all 300ms ease-in-out;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}

.overlayMyPage-after {
  opacity: 1;
  transform: translateX(0px);
  outline: none;
}

.overlayMyPage-before {
  opacity: 0;
  transform: translateX(200px);
}
/* .overlayInfo-base {
  outline: none;
  opacity: 0;
  transform: translateY(-200px);
  transition: all 300ms ease-in-out;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}

.overlayInfo-after {
  opacity: 1;
  transform: translateY(0px);
  outline: none;
}

.overlayInfo-before {
  opacity: 0;
  transform: translateY(-200px);
} */

/* .overlayProfile-base {
  opacity: 0;
  transform: translateX(200px);
  transition: all 300ms ease-in-out;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}

.overlayProfile-after {
  opacity: 1;
  transform: translateX(0px);
  outline: none;
}

.overlayProfile-before {
  opacity: 0;
  transform: translateX(200px);
}
.overlayDeli-base {
  opacity: 0;
  transform: translateX(200px);
  transition: all 300ms ease-in-out;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}

.overlayDeli-after {
  opacity: 1;
  transform: translateX(0px);
  outline: none;
}

.overlayDeli-before {
  opacity: 0;
  transform: translateX(200px);
} */

/* 左からIN */

.overlayLeft-base {
  opacity: 0;
  transform: translateX(200px);
  transition: all 300ms ease-in-out;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}

.overlayLeft-after {
  opacity: 1;
  transform: translateX(0px);
  outline: none;
}

.overlayLeftbefore {
  opacity: 0;
  transform: translateX(200px);
  outline: none;
}

/* .overlayReserve-base {
  opacity: 0;
  transform: translateX(200px);
  transition: all 300ms ease-in-out;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}

.overlayReserve-after {
  opacity: 1;
  transform: translateX(0px);
  outline: none;
}

.overlayReserve-before {
  opacity: 0;
  transform: translateX(200px);
}
.overlayHistory-base {
  opacity: 0;
  transform: translateX(200px);
  transition: all 300ms ease-in-out;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}

.overlayHistory-after {
  opacity: 1;
  transform: translateX(0px);
  outline: none;
}

.overlayHistory-before {
  opacity: 0;
  transform: translateX(200px);
} */

/* .overlayInquiry-base {
  opacity: 0;
  transform: translateX(200px);
  transition: all 300ms ease-in-out;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.overlayInquiry-after {
  opacity: 1;
  transform: translateX(0px);
  outline: none;
}

.overlayInquiry-before {
  opacity: 0;
  transform: translateX(200px);
} */

/* 写真のところ */
.slick-prev :before,
.slick-next :before {
  color: rgba(106, 106, 106, 0.75);
}

.modalImage-base {
  opacity: 0;
  /* transform: translateX(200px); */
  transition: all 300ms ease-in-out;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}
.modalImage-after {
  opacity: 1;
  outline: none;
  /* transform: translateX(0px); */
}

.modalImage-before {
  opacity: 0;
  /* transform: translateX(200px); */
}

.overlayThreeD-base {
  opacity: 0;
  /* transform: translateX(200px); */
  transition: all 300ms ease-in-out;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}
.overlayThreeD-after {
  opacity: 1;
  outline: none;
  /* transform: translateX(0px); */
}

.overlayThreeD-before {
  opacity: 0;
  /* transform: translateX(200px); */
}

/* .overlayCreatorProfile-base {
  opacity: 0;
  transform: translateX(200px);
  transition: all 300ms ease-in-out;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}

.overlayCreatorProfile-after {
  opacity: 1;
  transform: translateX(0px);
  outline: none;
}

.overlayCreatorProfile-before {
  opacity: 0;
  transform: translateX(200px);
} */

/* .overlayMemerImageLayout-base {
  opacity: 0;
  transform: translateX(200px);
  transition: all 300ms ease-in-out;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  outline: none;
}

.overlayMemerImageLayout-after {
  opacity: 1;
  transform: translateX(0px);
  outline: none;
}

.overlayMemerImageLayout-before {
  opacity: 0;
  transform: translateX(200px);
} */

.subHeader {
  position: fixed;
  margin: 0 auto;
  top: 0;
  background-color: white;
  z-index: 1;
  width: 500px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
@media screen and (max-width: 499px) {
  .subHeader {
    width: 375px;
  }
}

.subHeader-none {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

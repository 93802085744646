/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap"); */
body {
  font-family: "Noto Sans JP", sans-serif;
}
/*input type="file"の文字削除*/
.u-display-none {
  display: none;
}

/*********** Section ***********/
.c-main {
  padding: 96px 0;
}
.c-section-wrapin {
  margin: 0 auto;
  max-width: 575px;
  position: relative;
  padding: 0 1rem;
  text-align: center;
  width: 100%;
}

.c-section-container {
  margin: 0 auto;
  max-width: 400px;
  padding: 1rem;
  height: auto;
  width: calc(100% - 2rem);
}

.c-section__loading {
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-flow: column;
  justify-content: center;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
}

.c-section__loading > p {
  color: #444;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.p-grid__column {
  align-items: center;
  display: flex;
  flex-flow: column;
}

.p-grid__row {
  display: flex;
  flex-flow: row wrap;
}

.p-grid__list-images {
  display: flex;
  flex-flow: wrap;
}

.p-grid__list-images > .p-media__thumb {
  margin: 0.5rem;
  width: calc(50% - 1rem);
}

.p-media__thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.p-media__thumb::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.p-media__thumb > img {
  position: absolute;
  object-fit: cover;
  object-position: center;
  top: 0;
  left: 0;
  width: 100%;
}

img {
  max-width: 160%; /* カメラの画像が縦長だったので１６０％にしてますが変だったら直してください*/
  max-height: 100%;
  width: auto;
  height: auto;
}

.img-container--table-cell {
  position: relative;
  width: 30px;
  height: 30px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border: 1px solid darkgray;
}

.img-container--table-cell img {
  vertical-align: middle;
}

/* Auth周りの親要素 */
.container {
  width: 500px;
  margin: 0 auto;
  top: 0;
  transition: all 0.3s;
}
/* 
.containerUp {
  width: 500px;
  margin: 0 auto;
  bottom: -1000px;
  transition: all 0.3s;
} */

/* トップページのヘッダー系 */
/* .dummyArea {
  flex: 1;
  display: inline-block;
}

.mainTitle {
  flex: 3;
  display: inline-block;
}

.hamburgerMenu {
  flex: 1;
  display: inline-block;
  cursor: pointer;
} */

/* img.hamburger {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  float: right;
} */

/* 検索バー */
.searchBar {
  width: 480px;
  height: 30px;
  margin: 0 auto;
  margin-top: 71px;
  margin-bottom: 14px;
  border-radius: 15px;
  background-color: rgb(218, 214, 214);
  display: flex;
}

.borderBottom {
  height: 0;
  border-bottom: module-spacer--mediummodule-spacer--medium.5px solid black;
  transition: all 0.3;
}

.borderBottomDummy {
  height: 1000px;
  transition: all 0.3s;
}

.lupeIcon {
  margin-left: 10px;
  transform: scale(-1, 1);
}

.searchBox2 {
  width: 400px;
  margin-left: 20px;
  display: inline-block;
}

.searchInput {
  background-color: rgb(218, 214, 214);
  border: none;
  width: 400px;
  height: 28px;
  outline: none;
}

/* ユーザーのアイコンとフォローがわかるバー */
/* .userInfoBar { */
/* border-top: 0.5px solid black; */
/* height: 80px;
  display: flex;
} */

/* .userIcon {
  width: 60px;
  margin: 10px 0 10px 10px;
  flex: 1;
}

.iconBox {
  width: 56px;
  height: 56px;
}

.Icon {
  float: right;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid gray;
}

.userName {
  width: 60px;
  margin: 10px 0 10px 10px;
  flex: 1;
}

.Name {
  line-height: 33px;
  font-size: 11px;
  color: gray;
}

.userDummy {
  width: 60px;
  margin: 10px 0 10px 10px;
  flex: 4;
}

.userFollow {
  width: 60px;
  margin: 10px 0 10px 10px;
  flex: 2;
}

.followBtn {
  margin: 0 auto;
  margin-top: 20px;
  background-color: rgb(15, 161, 219);
  color: white;
  width: 90px;
  font-size: 13px;
  text-align: center;
} */

/* メインの写真 */

.imageArea {
  width: 500px;
  height: 625px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 投稿タイトル */
/* 
.articleTitle {
  width: 500px;
}

.articleTitle h2 {
  text-align: center;
  font-weight: lighter;
} */

/* 投稿内容 */

.articleArea {
  margin: 0 auto;
  width: 480px;
}
.articleArea p {
  font-size: 12px;
  color: gray;
}

/* コメントと欲しい */

.commentWant {
  display: flex;
}

.comment {
  flex: 1;
}

.commentArea {
  width: 60px;
  float: right;
  margin-right: 30px;
}

.commentArea p {
  font-size: 10px;
  text-align: center;
}

.commentIcon {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  display: block;
}

.want {
  flex: 1;
}

.wantArea {
  width: 60px;
  margin-left: 30px;
}

.wantArea p {
  font-size: 10px;
  text-align: center;
}

.star {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  display: block;
}

/* 区切り .separation {
  height: 10px;
  background-color: rgb(199, 185, 165);
} */

/* ハンバーガー用クラス */
/* .menuContainer {
  width: 500px;
  height: 100%;
  margin: 0 auto;
  z-index: 3;
  position: absolute;
  transition: all 0.3s;
  top: -1000px;
} */

/* 
.menuContainerClose {
  width: 500px;
  height: 100%;
  margin: 0 auto;
  z-index: 3;
  position: fixed;
  transition: all 0.1s;
  top: 0;
} */

/* ハンバーガーメニュー時のトップの挙動 */
/* .headerBorder {
  display: inline-block;
  border-bottom: 0.5px solid black;
  position: fixed;
  background-color: white;
  top: 0;
  z-index: 1;
  height: 56px;
  transition: all 0.3s;
} */

/* .headerBorderReturn {
  display: inline-block;
  border-bottom: 0.5px solid black;
  position: fixed;
  background-color: white;
  top: 0;
  z-index: 1;
  height: 1000px;
  transition: all 0.1s;
} */

/* 5枚版j*/
ul.MuiGridListTile-root {
  overflow: hidden;
  background-color: red;
}

/* 余計なbodyのmarginを削除 */
body {
  margin: 0 0 0 0;
}

.slick-prev :before,
.slick-next :before {
  color: rgba(106, 106, 106, 0.75);
}

.my-custom-textarea textarea {
  outline: 1px solid red;
}
